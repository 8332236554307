import React from 'react'
import { Scoped } from 'kremling'
import screenfull from 'screenfull';
import styles from './root.krem.css'
import { links } from './root.helper.js'
import { Link } from '@reach/router'
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import DocumentTitle from 'react-document-title';
import Helmet from 'react-helmet';
import SiderMenu from './components/SiderMenu';
import HeaderCustom from './components/HeaderCustom';
import { Layout, Row, message, notification, Button, Spin, Card, Icon, Menu, Popover, Col } from 'antd';
const { Content, Footer } = Layout;
import fetchWithCache from '@portal/fetchWithCache'
import { msalApp, signOut } from '../../Server/src/AuthProvider'
const { Header, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const { Meta } = Card;

// appInsights Tracking
const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'a90f8c46-e263-4c44-9a5d-91af9b1ad5de',
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();
// appInsights.setAuthenticatedUserContext(msalApp.getAccount().idTokenClaims.oid);

class Root extends React.Component {

  state = {
    hasError: false,
    collapsed: false,
    title: '',
    user: '',
    mode: 'inline',
    openKey: '',
    selectedKey: '',
    firstHide: true,
    loadingData: false,
    leftMenu: []
  };

  constructor(props) {
    super(props);
    this.initMenu = this.initMenu.bind(this);
  }

  componentWillMount() {
    this.initMenu();
  }

  initMenu() {
    this.setState(prev => {
      return { hasError: false, loadingData: true }
    })
    this.getLeftMenu().subscribe(
      (results) => {
        this.setState(prev => {
          return { leftMenu: results, loadingData: false }
        })
      },
      (err => {
        this.setState(prev => {
          return { hasError: true, loadingData: false }
        })
        console.log('err', err)
        message.error('There are some problems loading menu.');
      })
    )
  }

  getLeftMenu() {
    return new fetchWithCache().fetchPortal(
        `menu`
    )
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidCatch (error, info) {
    this.setState({hasError: true})
  }

  screenFull = () => {
    if (screenfull.enabled) {
        screenfull.request();
    }
  };
  menuClick = e => {
      console.log(e);
      e.key === 'logout' && this.logout();
  };
  logout = () => {
      localStorage.removeItem('user');
      signOut();
      // this.props.history.push('/login')
  };
  popoverHide = () => {
      this.setState({
          visible: false,
      });
  };
  handleVisibleChange = (visible) => {
      this.setState({ visible });
  };

  render () {
    const { title } = this.state;
    const { auth = { data: {} }, responsive = { data: {} } } = this.props;
    const { selectedKey, openKey, firstHide, collapsed } = this.state;
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

    console.log(auth);
    const avatarStyle = {
      width: '25px'
    };
    return (
      // <DocumentTitle title={title} postcss={styles}>
      <div>
        <Helmet>
            <title>WorkAlly</title>
          </Helmet>
          <Layout>
              {!responsive.data.isMobile && 
                <Sider
                trigger={null}
                breakpoint="lg"
                collapsible 
                collapsed={this.state.collapsed}
                style={{ overflowY: 'auto' }}
                >
                  <Link to="/">
                    <div className="logo" />
                  </Link>
                  {this.state.loadingData && 
                  <Spin style={{ textAlign: 'center', width: '100%' }} indicator={antIcon}/>}
                  {this.state.hasError &&
                    <div style={{ textAlign: 'center', width: '100%' }}>
                      <Row className="ant-result ant-result-warning">
                        <Icon type="warning" theme="filled" style={{ color: '#faad14', fontSize: 30 }} />
                      </Row>
                      <Row>
                        There are some problems loading menu.
                      </Row>
                      <Row style={{ marginTop: 5 }}>
                        <Button type="primary" key="console" onClick={this.initMenu}>
                          Retry
                        </Button>
                      </Row>
                    </div>
                  }
                  <SiderMenu
                      menus={this.state.leftMenu}
                      // onClick={this.menuClick}
                      mode="inline"
                      selectedKeys={[selectedKey]}
                      openKeys={firstHide ? null : [openKey]}
                      // onOpenChange={this.openMenu}
                  />
                </Sider> 
              }
              
              <Layout style={{flexDirection: 'column'}}>
              <Header className="custom-theme header" >
                {
                    responsive.data.isMobile ? (
                        <Popover content={<SiderCustom path={path} popoverHide={this.popoverHide} />} trigger="click" placement="bottomLeft" visible={this.state.visible} onVisibleChange={this.handleVisibleChange}>
                            <Icon type="bars" className="header__trigger custom-trigger" />
                        </Popover>
                    ) : (
                        <Icon
                            className="header__trigger custom-trigger"
                            type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
                            onClick={this.toggle}
                        />
                    )
                }
                <Menu
                    mode="horizontal"
                    style={{ lineHeight: '64px', float: 'right' }}
                    onClick={this.menuClick}
                >
                    {/* <Menu.Item key="pwa">
                        <PwaInstaller />
                    </Menu.Item> */}
                    <Menu.Item key="full" onClick={this.screenFull} >
                        <Icon type="arrows-alt" onClick={this.screenFull} />
                    </Menu.Item>
                   {/* <Menu.Item key="1">
                        <Badge count={25} overflowCount={10} style={{marginLeft: 10}}>
                            <Icon type="notification" />
                        </Badge>
                  </Menu.Item> */}
                    <SubMenu title={<span className="avatar"><img src="https://png.pngtree.com/svg/20170602/0db185fb9c.png" style={avatarStyle} alt="头像" /><i className="on bottom b-white" /></span>}>
                      {/*  <MenuItemGroup title="User">
                            <Menu.Item key="setting:1">Profile - {this.state.user}</Menu.Item>
                  <Menu.Item key="setting:2">Setting</Menu.Item> */}
                            <Menu.Item key="logout"><span onClick={this.logout}>Logout</span></Menu.Item>
                      {/*  </MenuItemGroup>
                        <MenuItemGroup title="Settings">
                            <Menu.Item key="setting:3">Suscription</Menu.Item>
                            <Menu.Item key="setting:4">Configure</Menu.Item>
                      </MenuItemGroup> */}
                    </SubMenu>
                </Menu>
            </Header>
                  {/* <HeaderCustom toggle={this.toggle} collapsed={this.state.collapsed} user={auth.data || {}} /> */}
                  <Content style={{ overflow: 'initial', flex: '1 1 0' }}>
                    <div className='content' id="content">
                      <Row gutter={24} style={{ margin: 18, marginTop: 24 }}>
                        <Col className="gutter-row" md={4}>
                          <Link to="/company">
                            <Card
                              hoverable
                              cover={<img alt="example" src="https://img.icons8.com/clouds/344/company.png" />}>
                              <Meta title="Company" description="Start Analyzing your Company" />
                            </Card>
                          </Link>
                        </Col>
                        <Col className="gutter-row" md={6}>
                        </Col>
                      </Row>
                    </div>
                  </Content>
                  <Footer style={{ textAlign: 'center', position: 'absolute', bottom: 0, background: 'transparent' }}>
                    AllySuite ©{new Date().getFullYear()} Created by Allyfoundry.com
                  </Footer>
              </Layout>
          </Layout>
      </div>
      // </DocumentTitle>

      // <Scoped postcss={styles}>
      //   {
      //     this.state.hasError ? (
      //       <div className='root navBarHeight'>
      //         Error
      //       </div>
      //     ) : (
      //       <div>
      //         <div className='root navBarHeight'>
      //           {
      //             links.map((link) => {
      //               return (
      //                 <Link
      //                   key={link.href}
      //                   className='primary-navigation-link'
      //                   to={link.href}
      //                 >
      //                   {link.name}
      //                 </Link>
      //               )
      //             })
      //           }
      //         </div>
      //         <div className='content' id="content">
      //         </div>
      //     </div>
      //     )
      //   }
      // </Scoped>
    )
  }
}

export default withAITracking(reactPlugin, Root);

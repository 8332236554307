export const links = [
  {
    title: 'Start',
    icon: 'api', 
    key: '/start'
  },
  {
    title: 'Administration',
    icon: 'control', 
    key: '/administration'
  },
  {
    title: 'Company',
    icon: 'home', 
    key: '/company'
  }
]
